import React, { useEffect } from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useField } from 'formik';
import { makeStyles } from "@material-ui/core/";
import { Box, FormHelperText } from '@material-ui/core';
import FormLabel from './form-label';
import { isEmpty } from 'lodash';
import { useTranslator } from '../../utilities/hooks/useTranslator';
import MaterialFormLabel from "@material-ui/core/FormLabel";
import clsx from "clsx";


const useStyle = makeStyles((theme) => ({
    label: {
        minWidth: " max-content",
        margin: "0 8px 8px 0",
      },
  }));

export default function CheckboxGroup({
    options = [],
    isConditional = false,
    optional,
    label,
    tooltip,
    fullWidth = false,
    disabled = false,
    ...props
  }) {
  const [field, meta, helpers] = useField(props);
  const classes = useStyle();
  const { value } = field;
  const t = useTranslator();

  useEffect(()=>{
    if(!value || isEmpty(value)){
    const initialValue = {}
    options.forEach((el)=>{
        initialValue[el.name]= !!el.defaultValue
      })
    helpers.setValue(initialValue,true);}
  },[])

  const handleChange = (event) => {
    helpers.setValue({ ...value, [event.target.name]: event.target.checked });
  };

  function _renderHelperText() {
    const { error } = meta;

    if (error) {
      return error;
    }
  }
  return (
    <FormGroup fullWidth={fullWidth} error={meta.error != null}>
        {label != null ? (
        <MaterialFormLabel className={clsx({ [classes.label]: label })}>
          <FormLabel optional={optional} label={t({needle: label})} tooltip={tooltip} />
        </MaterialFormLabel>
      ) : null}
      {options.map((option) => {
          return (
            <Box>
              <FormControlLabel
                key={option.name}
                value={option.name}
                control={
                    <Checkbox
                        name={option.name}
                        checked={value ? value[option.name]: false}
                        onChange={handleChange}
                        />}
                label={
                  <FormLabel label={t({ needle: option.label})} tooltip={option.tooltip} />
                }
              />
            </Box>
          );})}
        {!isConditional ? (
        <FormHelperText error={meta.error != null}>{_renderHelperText()}</FormHelperText>
      ) : null}
    </FormGroup>
  );
}
