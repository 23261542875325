import { takeLatest } from "@redux-saga/core/effects";
import { call, put } from "redux-saga/effects";
import {
  START_EXPORT,
  EXPORT_DATA_TAKEN
} from "../constants";
import { enqueueSnackbar } from "../snackbars/actions";
import { finishExport } from "./actions";

function* startExport(action){
  let data = null
  yield put(
    enqueueSnackbar({
      message: {
        needle: "label.exportTable.startExport"
      },
      options: {
        key: "startExport",
        variant: "success",
        autoHideDuration: 8000,
      },
    })
  );
  try{
    data = yield call(action.payload.getData, action.payload.apiPayload);
  }catch(e){
    yield put(
      enqueueSnackbar({
        message: {
          needle: "label.exportTable.error.exportDataRequestError"
        },
        options: {
          key: "exportDataRequestError",
          variant: "error",
          autoHideDuration: 8000,
        },
      })
    );
    yield put(finishExport())
    return
  }
  yield put({
    type: EXPORT_DATA_TAKEN,
    payload: {...action.payload, data},
  });
}


const ExportSaga = function* () {
  yield takeLatest(START_EXPORT, startExport);
};

export default ExportSaga;
