import React from "react";
import CheckboxGroup from "../form/checkbox-group";
import { useTranslator } from "../../utilities/hooks/useTranslator";
import { Form, Formik } from "formik";
import { Box, Grid } from "@material-ui/core";
import SpinnerButton from "../spinner-button/spinner-button";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { enqueueSnackbar } from "../../store/snackbars/actions";

const MAX_EXPORT_SIZE = 50000

export default function ExportAllModalContent({ columns, handleConfirm, handleClose, exportlines }) {
    const t = useTranslator();
    const { exporting } = useSelector(
      (state) => state.ExportReducer
    );
    const dispatch = useDispatch()
return (
<Formik
    initialValues={{}}
    validationSchema={Yup.object().shape({columns: Yup.object().test(
      'at-least-one-selection',
      t({needle:"errors.requiredCheckbox", variables:[t({needle: "label.columns"})]}),
      (value, context) => {
        return Object.keys(value).some((key)=> {
          return value[key]
        });
      } )})}
    onSubmit={(fields) => {
      if(!exporting){
        handleConfirm(columns.filter((c)=>{return fields.columns[c.field]}))
        return
      }else{
        dispatch(enqueueSnackbar({
          message: {
            needle: "label.exportTable.error.exportInProgressError",
          },
          options: {
            variant: "error",
            autoHideDuration: 8000,
          },
        }))
      }
    }}
    onReset={() => {}}
  >
{({
                      values,
                      errors,
                      handleChange,
                      handleBlur,
                      touched,
                      setFieldValue,
                    }) => {
        return (
          <Form>
            { exportlines>MAX_EXPORT_SIZE ? 
                <Grid style={{padding: "10px"}}container direction="column" alignItems="center" justify="center">
                  {t({needle:"label.exportTable.error.tooManyRowsToExportError", variables:[MAX_EXPORT_SIZE]})}
                </Grid> :
            <Box p={2}>
              <CheckboxGroup
               name="columns"
               field="columns"
               title="columns"
               options={columns.map((c)=>({name: c.field, label: c.title}))}
               label="label.columns">
              </CheckboxGroup>
            </Box>}          
             <Grid container spacing="2" direction="row" justify="flex-end" >
                <Grid item>
                    { exportlines<MAX_EXPORT_SIZE && <SpinnerButton loading={null} type="submit" color="primary">
                      {t({
                        needle: "button.confirm",
                      })}
                    </SpinnerButton>}
                </Grid>
                <Grid item>
                <SpinnerButton loading={null} type="button" color="primary" onClick={()=>handleClose()}>
                  {t({
                    needle: "button.close",
                  })}
                </SpinnerButton>
                </Grid>
              </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};
