import {
  START_EXPORT,
  EXPORT_DATA_TAKEN,
  FINISH_EXPORT
} from "../constants";

const initialState = {
  exporting: false,
  apiFunction: null,
  filters: null,
  columns: [],
  tableTitle: null
};

const ExportReducer = (state = initialState, action) => {
  switch (action.type) {
    case EXPORT_DATA_TAKEN:{
      return {
        ...state,
        data: action.payload.data,
        filters: action.payload.filters,
        columns: action.payload.columns,
        tableTitle: action.payload.tableTitle,
      };
    }
    case START_EXPORT: {
      return {
        ...state,
        exporting:true,
      };
    }
    case FINISH_EXPORT: {
      return {
        ...state,
        exporting: false,
        data: null,
        filters: null,
        tableTitle: null,
      }
    }

    default: {
      return state;
    }
  }
};

export default ExportReducer;
