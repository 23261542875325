import { ACCOUNT_DETAILS_REQUEST } from "../../../constants";

export const getAccountDetails = (contractId, brand, partner, currency) => {
  return {
    type: ACCOUNT_DETAILS_REQUEST,
    contractId,
    brand,
    partner,
    currency,
  };
};
