import React from "react";
import BadgeStatus from "../../Layouts/BadgeStatus/BadgeStatus";
import YesNoBadge from "../../Layouts/BadgeStatus/yes-no-badge";
import {
  ADD_TEMPLATE_SUCCESS,
  ALL_LANGUAGES_SUCCESS,
  DELETE_CHANNEL_SUCCESS,
  DELETE_TEMPLATE_SUCCESS,
  GET_CHANNEL_SUCCESS,
  HEADER_FOOTER_SUCCESS,
  LANGUAGES_CHANNEL_SUCCESS,
  MANAGE_TEMPLATE_REQUEST,
  MANAGE_TEMPLATE_SUCCESS,
  MANAGE_TEMPLATE_VARIABLES_SUCCESS,
  RESET_CHANNEL,
  STYLE_LABEL,
  VERSION_CHANNEL_DETAILS_SUCCESS,
  VERSION_SUCCESS,
} from "../constants";
const getStatusMapping = (status) => {
  switch (status) {
    case "1":
      return { id: "2", label: "valid" };
    case "0":
      return {
        id: "4",
        label: "invalid",
        tooltipDescription: "label.notifications.template.invalidStatus",
      };
  }
};
const requestMapping = (request) => {
  return request === 1 ? true : false;
};
const initialState = {
  loading: true,
  data: [],
  channel: {
    templateVariables: [],
    templates: [],
    languages: [],
    headerFooter: [],
    version: null,
    channels: [],
    channelDetails: null,
    allLanguages: [],
  },
  channelDetails: [],
  columns: (brand, partner) => {
    return [
      {
        field: "templateName",
        title: "label.notifications.template.templateName",
        defaultSort: "asc",
      },
      {
        field: "valid",
        title: "label.notifications.template.valid",
        render: (rowData) => {
          const statusMapping = getStatusMapping(rowData["valid"]);
          return (
            <BadgeStatus
              id={statusMapping.id}
              label={statusMapping.label}
              justify={"center"}
              tooltip={statusMapping.id === "4" ? true : false}
              tooltipDescription={statusMapping.tooltipDescription}
            />
          );
        },
        getExportTypeFn: (rowData) => {
          const statusMapping = getStatusMapping(rowData["valid"]);
          return {
            style: STYLE_LABEL,
            value: statusMapping.label,
          };
        },
      },
      {
        field: "required",
        title: "label.notifications.template.required",
        render: (rowData) => {
          const requestMapped = requestMapping(rowData["required"]);
          return <YesNoBadge value={requestMapped} />;
        },
      },
      {
        field: "owner",
        title: "label.notifications.template.owner",
      },
      {
        field: "version",
        title: "label.notifications.template.version",
      },
      {
        field: "smsBrandId",
        title: "label.notifications.template.brand",
        render: () => {
          return brand.name;
        },
      },
      {
        field: "smsPartnerId",
        title: "label.notifications.template.partner",
        render: () => {
          return partner.name;
        },
      },
    ];
  },
  Detailscol: [
    {
      field: "channelName",
      title: "label.notifications.template.channelName",
      default: true,
    },
    {
      field: "language",
      title: "label.notifications.template.language",
      default: true,
    },
    {
      field: "version",
      title: "label.notifications.template.version",
    },
    {
      field: "contentTypeId",
      title: "label.notifications.template.contentTypeId",
      render: (rowData) => {
        return rowData["contentTypeId"] === 2 ? "text/html" : "text/plain";
      },
    },
    {
      field: "subject",
      title: "label.notifications.template.subject",
    },
    {
      field: "fromAddress",
      title: "label.notifications.template.fromAddress",
    },
  ],
};
const ManageTemplateReducer = (state = initialState, action) => {
  switch (action.type) {
    case MANAGE_TEMPLATE_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    }

    case MANAGE_TEMPLATE_REQUEST: {
      return {
        ...state,
        lastRequest: action,
        loading: true,
      };
    }

    case DELETE_CHANNEL_SUCCESS:
    case ADD_TEMPLATE_SUCCESS:
    case DELETE_TEMPLATE_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    case GET_CHANNEL_SUCCESS: {
      return {
        ...state,
        loading: false,
        channelDetails: action.payload,
      };
    }

    case RESET_CHANNEL: {
      return {
        ...state,
        loading: false,
        channelDetails: null,
      };
    }

    case VERSION_CHANNEL_DETAILS_SUCCESS: {
      return {
        ...state,
        channel: {
          ...state.channel,
          channelDetails: action.payload,
        },
      };
    }

    case MANAGE_TEMPLATE_VARIABLES_SUCCESS: {
      return {
        ...state,
        channel: {
          ...state.channel,
          templateVariables: action.payload,
        },
      };
    }
    case LANGUAGES_CHANNEL_SUCCESS: {
      return {
        ...state,
        channel: {
          ...state.channel,
          languages: action.payload.languagesByChannelName,
          channels: action.payload.channels,
        },
      };
    }
    case HEADER_FOOTER_SUCCESS: {
      return {
        ...state,
        channel: {
          ...state.channel,
          headerFooter: action.payload,
        },
      };
    }

    case VERSION_SUCCESS: {
      return {
        ...state,
        channel: {
          ...state.channel,
          version: action.payload,
        },
      };
    }

    case ALL_LANGUAGES_SUCCESS: {
      return {
        ...state,
        channel: {
          ...state.channel,
          allLanguages: action.payload,
        },
      };
    }

    default: {
      return state;
    }
  }
};
export default ManageTemplateReducer;
