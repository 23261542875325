import React from "react";
import {
  SELF_EXCLUSION_SUCCESS,
  UPDATE_SELF_EXCLUSION_SUCCESS,
  SELF_EXCLUSION_REQUEST,
  SELF_EXCLUSION_HISTORY_REQUEST,
  SELF_EXCLUSION_HISTORY_SUCCESS,
  SELF_EXCLUSION_HISTORY_FAILURE,
} from "../../../constants";
import YesNoBadge from "../../../../Layouts/BadgeStatus/yes-no-badge";
import Moment from "react-moment";
import DateFormatter from "../../../../Layouts/date-formatter/date-formatter";
import * as moment from "moment";
import { Box, Tooltip } from "@material-ui/core";
import Translate from "../../../../Layouts/Translator/Translate";
import FormTooltip from "../../../../Layouts/form/form-tooltip";

export const labelPrefix =
  "label.individualplayer.responsibleGame.selfExclusion.";

const initialState = {
  selfExclusion: {
    isLoading: true,
    data: {},
    columns: [
      {
        field: "playerIsSelfExcluded",
        title: labelPrefix + "playerSelfExcluded",
        render: (rowData) => {
          return (
            <YesNoBadge
              value={rowData["playerIsSelfExcluded"]}
              justify={"center"}
            />
          );
        },
      },
      {
        field: "requestedDate",
        title: labelPrefix + "startDate",
        render: (rowData) => {
          if (rowData != null && rowData["requestedDate"] != 0) {
            return <Moment format={"L LT"}>{rowData["requestedDate"]}</Moment>;
          }
        },
      },
      {
        field: "selfExclusionTime",
        title: labelPrefix + "endDate",
        render: (rowData) => {
          if (rowData && rowData.coolOffHours > 0 && rowData.playerIsSelfExcluded && rowData.nextLimitStartDate != null && rowData.expirationDate == null) {
            return <Box display={"flex"} alignItems={"flex-end"}><Moment format={"L LT"}>{rowData["nextLimitStartDate"]}</Moment><FormTooltip
              title={
                <Translate
                  needle={"label.player.selfExclusion.cooldownTooltip"}
                  variables={[rowData.coolOffHours, moment(rowData["requestedDate"]).format("L LT")]}
                />
              }
            /></Box>
          }
          if (
            rowData != null &&
            rowData["expirationDate"] != 0 &&
            moment(rowData["expirationDate"]).isBefore(moment("01/01/3000"))
          ) {
            return <Moment format={"L LT"}>{rowData["expirationDate"]}</Moment>;
          }
        },
      },
      {
        field: "reason",
        title: labelPrefix + "reason",
      },
      {
        field: "executedBy",
        title: labelPrefix + "excludedBy",
      },
      {
        field: "boUsername",
        title: labelPrefix + "BoUsername",
      },
    ],
  },
  selfExclusionHistory: {
    loading: true,
    history: [],
    columns: [
      {
        field: "TRANSACTION_DATE",
        title: labelPrefix + "updatedDate",
        render: (rowData) => (
          <DateFormatter format={"L LT"} value={rowData["TRANSACTION_DATE"]} />
        ),
      },
      {
        field: "PREVIOUS_VALUE",
        title: labelPrefix + "previousEndDate",
        render: (rowData) => (
          <DateFormatter format={"L LT"} value={rowData["PREVIOUS_VALUE"]} />
        ),
      },
      {
        field: "CURRENT_VALUE",
        title: labelPrefix + "currentEndDate",
        render: (rowData) => {
          if (
            rowData["CURRENT_VALUE"] != null &&
            moment(rowData["CURRENT_VALUE"]).isBefore(moment("01/01/3000"))
          ) {
            return (
              <DateFormatter format={"L LT"} value={rowData["CURRENT_VALUE"]} />
            );
          }
        },
      },
      {
        field: "CHANGE_SOURCE",
        title: labelPrefix + "excludedBy",
      },
      {
        field: "BO_OPERATOR",
        title: labelPrefix + "BoUsername",
      },
      {
        field: "NOTE",
        title: labelPrefix + "reason",
      },
    ],
  },
};

const SelfExclusionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SELF_EXCLUSION_REQUEST:
      return {
        ...state,
        selfExclusion: {
          ...state.selfExclusion,
          isLoading: true,
          lastRequest: action,
        },
      };
    case SELF_EXCLUSION_SUCCESS:
      return {
        ...state,
        selfExclusion: {
          ...state.selfExclusion,
          data: action.payload,
          isLoading: false,
        },
      };
    case UPDATE_SELF_EXCLUSION_SUCCESS:
      return {
        ...state,
      };
    case SELF_EXCLUSION_HISTORY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SELF_EXCLUSION_HISTORY_SUCCESS:
      return {
        ...state,
        selfExclusionHistory: {
          ...state.selfExclusionHistory,
          history: action.payload,
          loading: false,
        },
      };
    case SELF_EXCLUSION_HISTORY_FAILURE:
      return {
        ...state,
        selfExclusionHistory: {
          ...state.selfExclusionHistory,
          history: null,
          loading: false,
        },
      };

    default:
      return {
        ...state,
      };
  }
};

export default SelfExclusionReducer;
