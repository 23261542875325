import { useDispatch, useSelector } from "react-redux";
import React from "react";
import { getAccountDetails } from "../../store/players/individual-player/account-details/actions";
import dataExporter, { ExportType } from "../dataExporter";
import { STYLE_CURRENCY, STYLE_LABEL } from "../../store/constants";
import { useSession } from "./useSession";
import { getDataValue } from "../../Layouts/Table/utils";
import { useIntl } from "react-intl";
import { useTranslator } from "./useTranslator";
import { finishExport } from "../../store/export/actions";
//use only in authenticatedApp
const exportFn = (exportType, tableData, includeFilters, filters, columns, currencySymbol, intl, divisorAmount, t, title) => {
    const exportData = tableData.map((rowData) =>
        columns.map((columnDef) => {
        if (columnDef.splitExportStringVal && exportType === ExportType.PDF) {
          var middle = Math.floor(rowData[columnDef.field].length / 2);
          var s1 = rowData[columnDef.field].substr(0, middle);
          var s2 = rowData[columnDef.field].substr(middle);
          return s1 + "\n" + s2;
        }
        if (typeof columnDef.getExportValueFn === "function") {
          return columnDef.getExportValueFn(rowData) || "";
        }
        if (typeof columnDef.getExportTypeFn === "function") {
          const styleType = columnDef.getExportTypeFn(rowData);
          if (styleType != null) {
            switch (styleType.style) {
              case STYLE_CURRENCY: {
                const currencyDisplay = currencySymbol ? "symbol" : "code";
                return intl.formatNumber(styleType.value / divisorAmount, {
                  style: STYLE_CURRENCY,
                  currency: styleType.currency,
                  currencyDisplay: currencyDisplay,
                });
              }
              case STYLE_LABEL: {
                return t({ needle: styleType.value });
              }
              default: {
                throw Error("Unrecognized style type");
              }
            }
          }
        }
        return getDataValue(rowData, columnDef) || "";
      })
    );
    const exportColumns = columns.map((it) => t({needle: it.title}));
    const filename =
      "Export_" +  (t({ needle: title }) || "data").replace(" ", "_");
    let filterFields = includeFilters ? filters : null;
    switch (exportType) {
      case ExportType.CSV: {
        dataExporter.exportCsv(
          exportColumns,
          exportData,
          filename,
          includeFilters,
          filterFields,
          t({ needle: title })
        );
        break;
      }
      case ExportType.EXCEL: {
        dataExporter.exportExcel(
          exportColumns,
          exportData,
          filename,
          includeFilters,
          filterFields,
          t({ needle: title })
        );
        break;
      }
      case ExportType.PDF: {
        dataExporter.exportPDF(
          exportColumns,
          exportData,
          filename,
          includeFilters,
          filterFields,
          t({ needle: title })
        );
        break;
      }
      default: {
        throw Error("Unrecognized export type");
      }
    }
  };
export const useExport = ({columns, filters, data, exporting, tableTitle}) => {

    const {
        divisorAmount,
        currencySymbol,
      } = useSession();
    const dispatch = useDispatch()
    const intl = useIntl();
    const t = useTranslator();

  React.useEffect(() => {
    if(exporting && data){
        exportFn(ExportType.CSV,data,false,filters,columns,currencySymbol,intl, divisorAmount, t, tableTitle)
        dispatch(finishExport())
    }
  }, [data]);

  return { };
};
