import {
  START_EXPORT,
  FINISH_EXPORT
} from "../constants";

export const startExport = (exportProp) => {
  return {
    type: START_EXPORT,
    payload: exportProp
  };
};

export const finishExport = () => {
  return {
    type: FINISH_EXPORT
  };
};

