import axios from "axios";
import searchUtils from "../../utilities/searchUtils";

class PlayerService {
  async getPlayerSearchCriteria(brand, partner) {
    let url = "/gov/api/rest/v1/player/criteriaSearch";
    if (!partner.pam && partner.network) {
      url = "/gov/api/rest/v1/network_player/criteriaSearch";
    }

    const response = await axios.get(url);
    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid response for player search criteria");
  }

  async getColumnOverrides(brand, partner) {
    let url = process.env.PUBLIC_URL + "/column-overrides.json";

    const response = await axios.get(url);
    if (response && response.data) {
      return response.data[brand.id + "-" + partner.id];
    }
    throw Error("Invalid response for player search column overrides");
  }

  async searchPlayers(
    { brandSelected, partnerSelected, searchTerms, query, escapeCharPlus, fq },
    pepStatuses
  ) {
    let vipSensitive;
    let url = "/gov/api/rest/v1/players";
    if (!partnerSelected.pam) {
      url = "/gov/api/rest/v1/players";
    }
    if (searchTerms.VIP_SENSITIVE != null) {
      let val = searchTerms.VIP_SENSITIVE.split("-");
      if (val[0] === "both") {
        searchTerms.sensitive_b = "true";
        searchTerms.vip_b = "true";
      } else {
        searchTerms[val[0].toLowerCase()] = val[1];
      }
      vipSensitive = searchTerms.VIP_SENSITIVE;
      delete searchTerms.VIP_SENSITIVE;
    }
    if (
      searchTerms.vip_date_dt != null &&
      typeof searchTerms.vip_date_dt == "number"
    ) {
      searchTerms.vip_date_dt = searchUtils.formatDateForQuery(
        searchTerms.vip_date_dt
      );
    }
    if (
      searchTerms.sensitive_date_dt != null &&
      typeof searchTerms.sensitive_date_dt == "number"
    ) {
      searchTerms.sensitive_date_dt = searchUtils.formatDateForQuery(
        searchTerms.sensitive_date_dt
      );
    }
    if (
      searchTerms.CELLULAR_PHONE != null &&
      searchTerms.CELLULAR_PHONE.includes("+")
    ) {
      searchTerms.CELLULAR_PHONE = searchTerms.CELLULAR_PHONE.replace(
        "+",
        encodeURIComponent("+")
      );
    }

    if (
      searchTerms.HOME_PHONE != null &&
      searchTerms.HOME_PHONE.includes("+")
    ) {
      searchTerms.HOME_PHONE = searchTerms.HOME_PHONE.replace(
        "+",
        encodeURIComponent("+")
      );
    }
    if (pepStatuses != null && searchTerms["PEP_STATUS"] === "") {
      searchTerms["PEP_STATUS"] = pepStatuses;
    }

    let geoProvQuery = "";
    if (searchTerms.GEO_PROVIDER === "noProvider") {
      delete searchTerms.GEO_PROVIDER;
      geoProvQuery =
        '(GEO_PROVIDER:"" OR GEO_PROVIDER:"NULL" OR GEO_PROVIDER:"null" OR (*:* -GEO_PROVIDER: [* TO *]))';
    }

    if (searchTerms.ACCOUNT_TYPE && searchTerms.ACCOUNT_TYPE !== "") {
      var key = searchTerms.ACCOUNT_TYPE;
      var value = true;

      if (key === "*") value = "*";

      if (key === "STANDARD") {
        key = "*";
        value = "* -(STAFF_MEMBER:true OR TEST_PLAYER_ACCOUNT:true)";
      }
      searchTerms[key] = value;
      delete searchTerms.ACCOUNT_TYPE;
    }

    let luceneQuery = searchUtils.getBasicLuceneQuery(searchTerms);

    geoProvQuery =
      (luceneQuery !== "" && geoProvQuery !== "" ? " AND " : "") + geoProvQuery;
    luceneQuery = luceneQuery.replace(/\\/g, "") + geoProvQuery;

    const start = query.pageSize * query.page;
    const filterQuery = fq ? fq : null;
    const q = `(${luceneQuery})`;
    const brandId = brandSelected.id;
    const partnerId = partnerSelected.id;
    const rows = query.pageSize;
    let sort = null;
    let sortMode = null;
    if (query.orderBy) {
      sort = query.orderBy.field;
      sortMode = query.orderBy.order === "desc" ? "desc" : "asc";
    }

    const body = {
      q,
      brandId,
      partnerId,
      filterQuery,
      rows,
      start,
      sort,
      sortMode,
      escapeCharPlus,
    };

    const response = await axios.post(url, body);

    if (response && response.data) {
      if (vipSensitive) searchTerms.VIP_SENSITIVE = vipSensitive;
      return {
        data: response.data.collection,
        page: query.page,
        totalCount: response.data.totalCount,
      };
    }
    throw Error("Invalid response for search players");
  }

  async getPlayerLevels(brand, partner) {
    const response = await axios.get(
      "/gov/api/rest/v1/processes/player-levels",
      {
        params: {
          brand: brand.id,
          partner: partner.id,
        },
      }
    );
    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid response for player levels");
  }

  async getPlayerLevelFields(brand, partner, level, contractId, upgrade) {
    const params = {
      brand: brand.id,
      partner: partner.id,
      registrationLevel: level,
    };
    if (contractId != null) {
      params["contractId"] = contractId;
      params["isUpgrade"] = upgrade;
    }
    const response = await axios.get(
      "/gov/api/rest/v1/processes/player-field",
      {
        params,
      }
    );
    if (response && response.data) {
      return response.data.filter((it) => it.id !== 5004);
    }
    throw Error("Invalid response for player level fields");
  }

  async save(brand, partner, body, contractId, playerId) {
    let url = "/gov/api/rest/v2/players";
    let method = "post";
    let validStatus = 204;
    if (contractId != null && playerId != null) {
      url = `/gov/api/rest/v2/contracts/${contractId}/players/${playerId}`;
      method = "put";
      validStatus = 200;
    }
    const data = {
      ...body,
      brandCode: brand.id,
      partnerCode: partner.id,
    };
    const response = await axios({ method, url, data });
    if (!response || response.status !== validStatus) {
      throw Error("Invalid response for player save");
    }
  }

  async saveUpgradePlayer(brand, partner, body, contractId) {
    let url = `/gov/api/rest/v1/player/upgrade/${contractId}`;

    const data = {
      ...body,
      brandCode: brand.id,
      partnerCode: partner.id,
    };
    const response = await axios.put(url, data);

    if (response && response.status !== 204) {
      throw Error("Invalid response for upgrade player save");
    }
  }

  async getPlayerFieldsSchema() {
    const response = await axios.get(
      process.env.PUBLIC_URL + "/player-form-fields-schema.json"
    );
    return response.data;
  }

  async suspendGameAccount(contractId, playerId, body) {
    const url = `/gov/api/rest/v1/contracts/${contractId}/players/${playerId}/disable_status_player`;

    const response = await axios.put(url, body);
    if (response == null || response.status !== 200) {
      throw Error("Error suspending game account");
    }
  }

  async reactivateGameAccount(contractId, playerId, body) {
    const url = `/gov/api/rest/v1/contracts/${contractId}/players/${playerId}/reactivate_status_player`;

    const response = await axios.put(url, body);
    if (response == null || response.status !== 200) {
      throw Error("Error reactivating game account");
    }
  }

  async accountClosureStart(
    contractId,
    playerId,
    brand,
    partner,
    removeData,
    reason,
    username
  ) {
    const url = `/gov/api/rest/v2/contracts/${contractId}/players/${playerId}/account/lock/reserve`;

    const response = await axios.post(url, {
      brandCode: brand.id,
      partnerCode: partner.id,
      removeData: removeData,
      reason: reason,
      createdBy: username,
    });
    if (response && response.status !== 204) {
      throw Error("Error reactivating game account");
    }
  }

  async accountClosureCancel(contractId, playerId, brand, partner) {
    const url = `/gov/api/rest/v2/contracts/${contractId}/players/${playerId}/account/lock/cancel?brandId=${brand.id}&partnerId=${partner.id}`;

    const response = await axios.delete(url);
    if (response && response.status !== 204) {
      throw Error("Error reactivating game account");
    }
  }

  async accountClosureConfirm(contractId, playerId, brand, partner, username) {
    const response = await axios.post("/gov/api/rest/v2/lrt/longRunningTask", {
      brandId: brand.id,
      contractId,
      partnerId: partner.id,
      taskTypeId: 5,
      createdBy: `BO User: ${username}`,
    });

    if (response && response.data) {
      return response.data;
    }

    throw Error("Invalid response for Account Closure Confirm");
  }

  async blockade(contractId, playerId, brand, partner, username) {
    const response = await axios.put(
      `/gov/api/rest/plt/v1/ewl/plt-blockade/${contractId}`,
      {},
      {
        params: {
          brand: brand.id,
          partner: partner.id,
        },
      }
    );

    if (response) {
      return;
    }

    throw Error("Invalid response for blockade");
  }

  async applyCashOut(body) {
    const url = "/gov/api/rest/v1/player/cash_out";

    const response = await axios.put(url, body);

    if (response && response.status !== 204) {
      throw Error("Error reactivating game account");
    }
  }

  async getOrderActions() {
    const url = "/gov/api/rest/v2/orderAction";

    const response = await axios.get(url);

    if (response && response.data) {
      return response.data;
    }
    throw Error("Error on fetch order actions");
  }

  async getOrderTypes() {
    const url = "/gov/api/rest/v2/orderType";

    const response = await axios.get(url);

    if (response && response.data) {
      return response.data;
    }
    throw Error("Error on fetch order types");
  }

  async saveOrder(body) {
    const response = await axios.post("/gov/api/rest/v3/createOrder", body);

    if (response && response.status === 204) {
      return response;
    }
    throw Error("Error reactivating game account");
  }

  async deletePlatformNickname(brandId, partnerId, gameAccount, nicknameGns) {
    let url = "/gov/api/rest/v1/ams/nicknames/cancellation";

    const response = await axios.put(url, {
      brandId,
      partnerId,
      gameAccount,
      nicknameGns,
    });
    if (response && response.status !== 204) {
      throw Error("Invalid response for delete platform nickname");
    }
  }

  async convertTestPlayerAccount(contractId, playerId, body) {
    const url = `/gov/api/rest/v1/contracts/${contractId}/players/${playerId}/convert-test-player-account`;
    const response = await axios.put(url, body);
    if (response == null || response.status !== 200) {
      throw Error("Error convert test player account");
    }
  }

  async markUnmarkStaffMember(contractId, body) {
    const url = `/gov/api/rest/v1/orc/markUnmarkStaffMember/${contractId}`;

    const response = await axios.put(url, body);
    if (response == null || response.status !== 204) {
      const errorLabel =
        contractId == null
          ? ""
          : body.isStaffMember
          ? "Error unmark staff member account"
          : "Error convert staff member account";
      throw Error(errorLabel);
    }
  }
}

const playerService = new PlayerService();
export default playerService;
