import { takeEvery, takeLatest } from "@redux-saga/core/effects";
import axios from "axios";
import { call, put } from "redux-saga/effects";
import {
  CANCEL_ACCOUNT_CLOSURE_FAILURE,
  CANCEL_ACCOUNT_CLOSURE_REQUEST,
  CANCEL_ACCOUNT_CLOSURE_SUCCESS,
  CASH_OUT_FAILURE,
  CASH_OUT_REQUEST,
  CASH_OUT_SUCCESS,
  CONFIRM_ACCOUNT_CLOSURE_FAILURE,
  CONFIRM_ACCOUNT_CLOSURE_REQUEST,
  CONFIRM_ACCOUNT_CLOSURE_SUCCESS,
  CONSENTS_REQUEST,
  CONSENTS_SUCCESS,
  CONVERT_TEST_PLAYER_ACCOUNT_FAILURE,
  CONVERT_TEST_PLAYER_ACCOUNT_REQUEST,
  CONVERT_TEST_PLAYER_ACCOUNT_SUCCESS,
  DELETE_PLATFORM_NICKNAME_REQUEST,
  ORDER_ACTION_FAILURE,
  ORDER_ACTION_REQUEST,
  ORDER_ACTION_SUCCESS,
  ORDER_TYPE_FAILURE,
  ORDER_TYPE_REQUEST,
  ORDER_TYPE_SUCCESS,
  PLAYERS_ID_LIST_FAILURE,
  PLAYERS_ID_LIST_REQUEST,
  PLAYERS_ID_LIST_SUCCESS,
  PLAYERS_SEARCH_CRITERIA_FAILURE,
  PLAYERS_SEARCH_CRITERIA_REQUEST,
  PLAYERS_SEARCH_CRITERIA_SUCCESS,
  PLAYERS_SEARCH_FAILURE,
  PLAYERS_SEARCH_REQUEST,
  PLAYERS_SEARCH_SUCCESS,
  PLAYER_LEVELS_FAILURE,
  PLAYER_LEVELS_REQUEST,
  PLAYER_LEVELS_SUCCESS,
  PLAYER_LEVEL_FIELDS_FAILURE,
  PLAYER_LEVEL_FIELDS_REQUEST,
  PLAYER_LEVEL_FIELDS_SUCCESS,
  PLAYER_SAVE_FAILURE,
  PLAYER_SAVE_REQUEST,
  PLAYER_SAVE_SUCCESS,
  REACTIVATE_GAME_ACCOUNT_FAILURE,
  REACTIVATE_GAME_ACCOUNT_REQUEST,
  REACTIVATE_GAME_ACCOUNT_SUCCESS,
  SAVE_ORDER_FAILURE,
  SAVE_ORDER_REQUEST,
  SAVE_ORDER_SUCCESS,
  START_ACCOUNT_CLOSURE_FAILURE,
  START_ACCOUNT_CLOSURE_REQUEST,
  START_ACCOUNT_CLOSURE_SUCCESS,
  SUSPEND_GAME_ACCOUNT_FAILURE,
  SUSPEND_GAME_ACCOUNT_REQUEST,
  SUSPEND_GAME_ACCOUNT_SUCCESS,
  UPGRADE_PLAYER_SAVE_FAILURE,
  UPGRADE_PLAYER_SAVE_REQUEST,
  UPGRADE_PLAYER_SAVE_SUCCESS,
  BLOCKADE,
  MARK_UNMARK_STAFF_MEMBER_FAILURE,
  MARK_UNMARK_STAFF_MEMBER_SUCCESS,
  MARK_UNMARK_STAFF_MEMBER_REQUEST,
} from "../constants";
import { enqueueSnackbar } from "../snackbars/actions";
import playerService from "./player-service";

export const showSnackbar = async () => {
  const setting = await axios.get(
    process.env.PUBLIC_URL + "/configuration.json"
  );
  return setting.data.player;
};

const loadPlayerSearchCriteria = function* (action) {
  try {
    const columnOverrides = yield call(
      playerService.getColumnOverrides,
      action.brand,
      action.partner
    );
    const data = yield call(
      playerService.getPlayerSearchCriteria,
      action.brand,
      action.partner
    );
    yield put({
      type: PLAYERS_SEARCH_CRITERIA_SUCCESS,
      payload: data,
      columnOverrides,
    });
  } catch (e) {
    console.error("LoadPlayerSearchCriteria", e);
    yield put({
      type: PLAYERS_SEARCH_CRITERIA_FAILURE,
    });
  }
};

const searchPlayers = function* (action) {
  try {
    const data = yield call(playerService.searchPlayers, action.searchParams);
    yield put({
      type: PLAYERS_SEARCH_SUCCESS,
      payload: data,
    });
    if (!action.sensitiveVip) {
      const snackbar = yield call(showSnackbar);
      if (snackbar.sensitive_vip_snackbar) {
        yield put(
          enqueueSnackbar({
            message: {
              needle: "label.sensitiveVipSnackbar",
            },
            options: {
              key: "sensitiveVipSnackbar",
              variant: "info",
              autoHideDuration: 5000,
            },
          })
        );
      }
    }
  } catch (e) {
    console.error("SearchPlayers", e);
    yield put({
      type: PLAYERS_SEARCH_FAILURE,
    });
  }
};

const getPlayersIdList = function* (action) {
  try {
    const data = yield call(playerService.searchPlayers, action.searchParams);
    yield put({
      type: PLAYERS_ID_LIST_SUCCESS,
      payload: data.data.map((it) => it["CONTRACT_IDENTITY"]),
    });
  } catch (e) {
    console.error("GetPlayersIdList", e);
    yield put({
      type: PLAYERS_ID_LIST_FAILURE,
    });
  }
};

export const residentialAddress = [25, 23, 19, 20, 21, 24, 26];
export const billingAddress = [41, 39, 35, 36, 37, 40, 42];
const hiddenFields = [11000, 11001];
const visibleFields = (field) => {
  return !hiddenFields.includes(field.id);
};

const getGroups = (section, fields) => {
  let unflattedGroupedFields = [];
  if (section.groups) {
    unflattedGroupedFields.push(...section.groups);
  } else {
    section.sections.forEach((sec) => {
      unflattedGroupedFields.push(...sec.groups);
    });
  }

  return unflattedGroupedFields
    .map((group) => {
      return {
        fields: group.fields
          .map((it) =>
            Array.isArray(it)
              ? it
                  .map((subIt) => fields.find((field) => field.id === subIt))
                  .filter((field) => field)
              : fields.find((field) => field.id === it)
          )
          .filter((it) => (Array.isArray(it) ? it.length > 0 : it != null)),
      };
    })
    .filter((group) => group.fields.length > 0);
};

const parseFields = (sections, fields) => {
  let unflattedGroupedFields = [];
  sections.forEach((it) => {
    if (it.groups) {
      unflattedGroupedFields.push(it.groups);
    } else {
      it.sections.forEach((sec) => {
        unflattedGroupedFields.push(sec.groups);
      });
    }
  });
  const groupedFields = [...unflattedGroupedFields]
    .flat()
    .map((it) => it.fields)
    .flat()
    .flat();

  const toReturn = sections.map((section) => {
    var groups = getGroups(section, fields);

    var obj = section.groups
      ? { groups: getGroups(section, fields) }
      : {
          sections:
            groups.sections?.length > 0
              ? [(groups = getGroups(section, fields))]
              : [],
        };

    return {
      ...section,
      ...obj,
    };
  });

  const otherFields = fields.filter(
    (it) => visibleFields(it) && !groupedFields.includes(it.id)
  );
  if (otherFields.length > 0) {
    toReturn.push({
      name: "others",
      groups: [{ fields: otherFields }],
    });
  }
  return toReturn;
};
const getPlayerLevels = function* (action) {
  try {
    const data = yield call(
      playerService.getPlayerLevels,
      action.brand,
      action.partner
    );
    yield put({
      type: PLAYER_LEVELS_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("getPlayerLevels", e);
    yield put({
      type: PLAYER_LEVELS_FAILURE,
    });
  }
};

const getPlayerLevelFields = function* (action) {
  try {
    const sections = yield call(playerService.getPlayerFieldsSchema);
    const data = yield call(
      playerService.getPlayerLevelFields,
      action.brand,
      action.partner,
      action.level,
      action.contractId,
      action.upgrade
    );

    data.push({
      id: 120003,
      field: "label.player.form.120003",
      pattern: null,
      mandatory: null,
      minLength: null,
      maxLength: null,
      defaultValue: null,
      type: "Checkbox",
      regExListSelected: null,
      isAccessKey: null,
    });
    const parsedFields = parseFields(sections, data);
    yield put({
      type: PLAYER_LEVEL_FIELDS_SUCCESS,
      payload: {
        fields: data,
        sections: parsedFields,
        contractId: action.contractId,
      },
    });
  } catch (e) {
    console.error("getPlayerLevelFields", e);
    yield put({
      type: PLAYER_LEVEL_FIELDS_FAILURE,
    });
  }
};

const fetchConsents = async (brand, partner, language, registrationLevelId) => {
  const url = "/gov/api/rest/v1/consents-configuration";
  const params = {
    brand: brand.id,
    partner: partner.id,
    language,
    consentLanguage: true,
    statusEnabled: true,
    "user-type-id": registrationLevelId,
  };
  const response = await axios.get(url, { params });
  if (response && response.data) {
    return response.data;
  }
  throw new Error("Error loading consents");
};
const getConsents = function* (action) {
  try {
    const data = yield call(
      fetchConsents,
      action.brand,
      action.partner,
      action.language,
      action.registrationLevelId
    );
    yield put({
      type: CONSENTS_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("getPlayerLevelFields", e);
  }
};

const savePlayer = function* (action) {
  var isRegistered = false;
  try {
    if (action.isUpgrade && !action.isUpgraded) {
      yield put(
        enqueueSnackbar({
          message: {
            needle: "ko.isNotUpgraded",
          },
          options: {
            key: "upgradePlayer",
            variant: "failure",
          },
        })
      );
    } else {
      const data = yield call(
        action.isUpgrade ? playerService.saveUpgradePlayer : playerService.save,
        action.brand,
        action.partner,
        action.body,
        action.contractId,
        action.playerId,
        action.isUpgrade
      );
      yield put({
        type: !action.isUpgrade
          ? PLAYER_SAVE_SUCCESS
          : UPGRADE_PLAYER_SAVE_SUCCESS,
        data,
      });
      yield put(
        enqueueSnackbar({
          message: {
            needle:
              action.contractId != null
                ? action.isUpgrade
                  ? "ok.saveUpgradePlayer"
                  : "ok.updatePlayer"
                : "ok.createPlayer",
          },
          options: {
            key: "savePlayer",
            variant: "success",
          },
        })
      );

      action.cb && typeof action.cb === "function" && action.cb();
    }
    isRegistered = true;
  } catch (e) {
    console.error("savePlayer", e);
    yield put({
      type: !action.isUpgrade
        ? PLAYER_SAVE_FAILURE
        : UPGRADE_PLAYER_SAVE_FAILURE,
    });
  }

  try {
    if (
      action.contractId == null &&
      action.body.markAsStaffMember &&
      isRegistered
    ) {
      const email = action.body.registrationLevelFieldVOs.find(
        (it) => it.id === "61"
      ).value;
      const body = {
        brandCode: action.brand.id,
        partnerCode: action.partner.id,
        email: email,
        isStaffMember: false,
      };
      yield call(playerService.markUnmarkStaffMember, action.contractId, body);
      yield put({
        type: MARK_UNMARK_STAFF_MEMBER_SUCCESS,
      });
      yield put(
        enqueueSnackbar({
          message: {
            needle: "ok.markStaffMember",
          },
          options: {
            key: "markStaffMember",
            variant: "success",
          },
        })
      );
    }
  } catch (e) {
    console.error("markStaffMember", e);
    e.data = "ko.markStaffMemberAddPly";
    yield put({
      type: MARK_UNMARK_STAFF_MEMBER_FAILURE,
    });
  }
};

const suspendGameAccount = function* (action) {
  try {
    yield call(
      playerService.suspendGameAccount,
      action.contractId,
      action.playerId,
      action.body
    );
    yield put({
      type: SUSPEND_GAME_ACCOUNT_SUCCESS,
    });
    yield put(
      enqueueSnackbar({
        message: {
          needle: "ok.suspendGameAccount",
        },
        options: {
          key: "suspendGameAccount",
          variant: "success",
        },
      })
    );
    action.cb != null && typeof action.cb === "function" && action.cb();
  } catch (e) {
    console.error("suspendGameAccount", e);
    yield put({
      type: SUSPEND_GAME_ACCOUNT_FAILURE,
    });
    action.errCb != null &&
      typeof action.errCb === "function" &&
      action.errCb();
  }
};

const reactivateGameAccount = function* (action) {
  try {
    yield call(
      playerService.reactivateGameAccount,
      action.contractId,
      action.playerId,
      action.body
    );
    yield put({
      type: REACTIVATE_GAME_ACCOUNT_SUCCESS,
    });
    yield put(
      enqueueSnackbar({
        message: {
          needle: "ok.reactivateGameAccount",
        },
        options: {
          key: "reactivateGameAccount",
          variant: "success",
        },
      })
    );
    action.cb != null && typeof action.cb === "function" && action.cb();
  } catch (e) {
    console.error("reactivateGameAccount", e);
    yield put({
      type: REACTIVATE_GAME_ACCOUNT_FAILURE,
    });
    action.errCb != null &&
      typeof action.errCb === "function" &&
      action.errCb();
  }
};

const startAccountClosure = function* (action) {
  try {
    yield call(
      playerService.accountClosureStart,
      action.contractId,
      action.playerId,
      action.brand,
      action.partner,
      action.removeData,
      action.reason,
      action.username
    );
    yield put({
      type: START_ACCOUNT_CLOSURE_SUCCESS,
    });
    yield put(
      enqueueSnackbar({
        message: {
          needle: "label.individualplayer.startAccountClosure.startSuccess",
        },
        options: {
          key: "startAccountClosure",
          variant: "success",
        },
      })
    );
    action.cb != null && typeof action.cb === "function" && action.cb(true);
  } catch (e) {
    console.error("startAccountClosure", e);
    action.errCb != null &&
      typeof action.errCb === "function" &&
      action.errCb();
    yield put({
      type: START_ACCOUNT_CLOSURE_FAILURE,
    });
  }
};

const cancelAccountClosure = function* (action) {
  try {
    yield call(
      playerService.accountClosureCancel,
      action.contractId,
      action.playerId,
      action.brand,
      action.partner
    );
    yield put({
      type: CANCEL_ACCOUNT_CLOSURE_SUCCESS,
    });
    yield put(
      enqueueSnackbar({
        message: {
          needle: "label.individualplayer.cancelAccountClosure.cancelSuccess",
        },
        options: {
          key: "cancelAccountClosure",
          variant: "success",
        },
      })
    );
    action.cb != null && typeof action.cb === "function" && action.cb(true);
  } catch (e) {
    console.error("cancelAccountClosure", e);
    action.errCb != null &&
      typeof action.errCb === "function" &&
      action.errCb();
    yield put({
      type: CANCEL_ACCOUNT_CLOSURE_FAILURE,
    });
  }
};

const confirmAccountClosure = function* (action) {
  try {
    yield call(
      playerService.accountClosureConfirm,
      action.contractId,
      action.playerId,
      action.brand,
      action.partner,
      action.username
    );
    yield put({
      type: CONFIRM_ACCOUNT_CLOSURE_SUCCESS,
    });
    yield put(
      enqueueSnackbar({
        message: {
          needle: "label.individualplayer.confirmAccountClosure.closedSuccess",
        },
        options: {
          key: "confirmAccountClosure",
          variant: "success",
        },
      })
    );
    action.cb != null && typeof action.cb === "function" && action.cb(true);
  } catch (e) {
    console.error("cancelAccountClosure", e);
    action.errCb != null &&
      typeof action.errCb === "function" &&
      action.errCb();
    yield put({
      type: CONFIRM_ACCOUNT_CLOSURE_FAILURE,
    });
  }
};

const confirmBlockade = function* (action) {
  try {
    yield call(
      playerService.blockade,
      action.contractId,
      action.playerId,
      action.brand,
      action.partner,
      action.username
    );
    yield put({
      type: BLOCKADE.SUCCESS,
    });
    yield put(
      enqueueSnackbar({
        message: {
          needle: "label.individualplayer.blockade.closedSuccess",
        },
        options: {
          key: "confirmBlockade",
          variant: "success",
        },
      })
    );
    action.cb != null && typeof action.cb === "function" && action.cb(true);
  } catch (e) {
    console.error("confirmBlockade", e);
    action.errCb != null &&
      typeof action.errCb === "function" &&
      action.errCb();
    yield put({
      type: BLOCKADE.FAILURE,
    });
  }
};

const cashOut = function* (action) {
  try {
    yield call(playerService.applyCashOut, action.body);
    yield put({
      type: CASH_OUT_SUCCESS,
    });
    yield put(
      enqueueSnackbar({
        message: {
          needle: "label.individualplayer.cashOut.successMessage",
        },
        options: {
          key: "cashOut",
          variant: "success",
        },
      })
    );
    action.cb != null && typeof action.cb === "function" && action.cb(true);
  } catch (e) {
    console.error("cashOut", e);
    action.errCb != null &&
      typeof action.errCb === "function" &&
      action.errCb();
    yield put({
      type: CASH_OUT_FAILURE,
    });
  }
};

const getOrderActions = function* (action) {
  try {
    const data = yield call(playerService.getOrderActions);
    yield put({
      type: ORDER_ACTION_SUCCESS,
      data,
    });
  } catch (e) {
    console.error("cashOut", e);

    yield put({
      type: ORDER_ACTION_FAILURE,
    });
  }
};

const getOrderTypes = function* (action) {
  try {
    const data = yield call(playerService.getOrderTypes);
    yield put({
      type: ORDER_TYPE_SUCCESS,
      data,
    });
  } catch (e) {
    console.error("cashOut", e);

    yield put({
      type: ORDER_TYPE_FAILURE,
    });
  }
};

const saveOrder = function* (action) {
  try {
    yield call(playerService.saveOrder, action.body);
    yield put({
      type: SAVE_ORDER_SUCCESS,
    });
    yield put(
      enqueueSnackbar({
        message: {
          needle: "label.individualplayer.createOrder.successMessage",
        },
        options: {
          key: "cashOut",
          variant: "success",
        },
      })
    );

    action.cb != null && typeof action.cb === "function" && action.cb(true);
  } catch (e) {
    console.error("cashOut", e);

    action.errCb != null &&
      typeof action.errCb === "function" &&
      action.errCb();

    yield put({
      type: SAVE_ORDER_FAILURE,
    });
  }
};

const deletePlatformNickname = function* (action) {
  try {
    // eslint-disable-next-line
    const data = yield call(
      playerService.deletePlatformNickname,
      action.brand.id,
      action.partner.id,
      action.contractId,
      action.nicknameGns
    );
    yield put(
      enqueueSnackbar({
        message: {
          needle:
            "label.individualplayer.cancelNicknameReservation.deleteSuccess",
        },
        options: {
          key: "updatePlatformNicknames",
          variant: "success",
          autoHideDuration: 2000,
        },
      })
    );
    action.cb != null && typeof action.cb === "function" && action.cb(true);
  } catch (e) {
    console.error("deletePlatformNickname", e);
    action.errCb != null &&
      typeof action.errCb === "function" &&
      action.errCb();
  }
};

const convertTestPlayerAccount = function* (action) {
  try {
    yield call(
      playerService.convertTestPlayerAccount,
      action.contractId,
      action.playerId,
      action.body
    );
    yield put({
      type: CONVERT_TEST_PLAYER_ACCOUNT_SUCCESS,
    });
    yield put(
      enqueueSnackbar({
        message: {
          needle: "ok.convertTestPlayerAccount",
        },
        options: {
          key: "convertTestPlayerAccount",
          variant: "success",
        },
      })
    );
    action.cb != null && typeof action.cb === "function" && action.cb(true);
  } catch (e) {
    console.error("convertTestPlayerAccount", e);
    yield put({
      type: CONVERT_TEST_PLAYER_ACCOUNT_FAILURE,
    });
    action.errCb != null &&
      typeof action.errCb === "function" &&
      action.errCb();
  }
};

const markUnmarkStaffMember = function* (action) {
  try {
    yield call(
      playerService.markUnmarkStaffMember,
      action.contractId,
      action.body
    );
    yield put({
      type: MARK_UNMARK_STAFF_MEMBER_SUCCESS,
    });
    yield put(
      enqueueSnackbar({
        message: {
          needle: action.body.isStaffMember
            ? "ok.unmarkStaffMember"
            : "ok.markStaffMember",
        },
        options: {
          key: "markUnmarkStaffMember",
          variant: "success",
        },
      })
    );
    action.cb != null && typeof action.cb === "function" && action.cb(true);
  } catch (e) {
    console.error("markUnmarkStaffMember", e);
    yield put({
      type: MARK_UNMARK_STAFF_MEMBER_FAILURE,
    });
    action.cb != null && typeof action.cb === "function" && action.cb(true);
  }
};

const PlayerSaga = function* externalUrlSaga() {
  yield takeLatest(PLAYERS_SEARCH_CRITERIA_REQUEST, loadPlayerSearchCriteria);
  yield takeLatest(PLAYERS_SEARCH_REQUEST, searchPlayers);
  yield takeLatest(PLAYERS_ID_LIST_REQUEST, getPlayersIdList);
  yield takeLatest(PLAYER_LEVELS_REQUEST, getPlayerLevels);
  yield takeLatest(PLAYER_LEVEL_FIELDS_REQUEST, getPlayerLevelFields);
  yield takeLatest(CONSENTS_REQUEST, getConsents);
  yield takeLatest(PLAYER_SAVE_REQUEST, savePlayer);
  yield takeLatest(UPGRADE_PLAYER_SAVE_REQUEST, savePlayer);
  yield takeLatest(SUSPEND_GAME_ACCOUNT_REQUEST, suspendGameAccount);
  yield takeLatest(REACTIVATE_GAME_ACCOUNT_REQUEST, reactivateGameAccount);
  yield takeLatest(START_ACCOUNT_CLOSURE_REQUEST, startAccountClosure);
  yield takeLatest(CANCEL_ACCOUNT_CLOSURE_REQUEST, cancelAccountClosure);
  yield takeLatest(CONFIRM_ACCOUNT_CLOSURE_REQUEST, confirmAccountClosure);
  yield takeLatest(BLOCKADE.REQUEST, confirmBlockade);
  yield takeLatest(CASH_OUT_REQUEST, cashOut);
  yield takeLatest(ORDER_ACTION_REQUEST, getOrderActions);
  yield takeLatest(ORDER_TYPE_REQUEST, getOrderTypes);
  yield takeEvery(SAVE_ORDER_REQUEST, saveOrder);
  yield takeLatest(DELETE_PLATFORM_NICKNAME_REQUEST, deletePlatformNickname);
  yield takeLatest(
    CONVERT_TEST_PLAYER_ACCOUNT_REQUEST,
    convertTestPlayerAccount
  );
  yield takeLatest(MARK_UNMARK_STAFF_MEMBER_REQUEST, markUnmarkStaffMember);
};

export default PlayerSaga;
