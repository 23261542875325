import Paper from "@material-ui/core/Paper";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import CloudDownloadOutlinedIcon from "@material-ui/icons/CloudDownloadOutlined";
import { debounce } from "lodash";
import MaterialTable, {
  MTableBody,
  MTableEditField,
  MTableEditRow,
  MTableHeader,
  MTableToolbar,
} from "material-table";
import React, { useState } from "react";
import { ReactComponent as NoData } from "../../Images/icon_no_data.svg";
import { ReactComponent as NoSearchResultIcon } from "../../Images/icon_no_search_found.svg";
import { useTranslator } from "../../utilities/hooks/useTranslator";
import Pagination from "./pagination";
// Import Custom Icon(s)
import Box from "@material-ui/core/Box";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import ArrowBack from "@material-ui/icons/ArrowBack";
import Cancel from "@material-ui/icons/Cancel";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import Search from "@material-ui/icons/Search";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import SkipPreviousIcon from "@material-ui/icons/SkipPrevious";
import Skeleton from "@material-ui/lab/Skeleton";
import Axios from "axios";
import clsx from "clsx";
import { Formik } from "formik";
import * as moment from "moment";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import { isMobileOnly } from "react-device-detect";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import {
  CRITERIA_TYPE_DATE,
  CRITERIA_TYPE_DATERANGE,
  CRITERIA_TYPE_DATETIMERANGE,
  CRITERIA_TYPE_PERIOD,
  CRITERIA_TYPE_SELECT,
  STYLE_CURRENCY,
  STYLE_LABEL,
} from "../../store/constants";
import { getUserPreferencesHandler } from "../../store/user-preferences/action";
import dataExporter, { ExportType } from "../../utilities/dataExporter";
import { useSession } from "../../utilities/hooks/useSession";
import { useUserPreferences } from "../../utilities/hooks/useUserPreferences";
import NoDataToShow from "../../utilities/no-data-to-show";
import { getTranslatedLabels } from "../../utils";
import { FormField } from "../form";
import { getValidationSchema } from "../form/utils";
import Translate from "../Translator/Translate";
import Action from "./action";
import ColumnsSelector from "./columns-selector";
import ExportMenu from "./export-menu";
import ListTable from "./list-table";
import TableCell from "./table-cell";
import { getDataValue, getRenderDataValue } from "./utils";
import Modal from "../Modal/Modal";
import ExportAllModalContent from "./export-all-modal-content";

const Table = ({
  title,
  columns,
  validationDependencies = null,
  type,
  resize = true,
  isLoading: loading,
  onFetch,
  data,
  actions = [],
  components = {},
  pageSize = 5,
  maxPageSize = 100,
  orderBy,
  exportButton = false,
  filters = [],
  lastRowColor,
  detail,
  onRowClick,
  placeholder,
  detailKey,
  openedDetails,
  rowClickDisabled = false,
  allowSorting = true,
  searchBar = true,
  tableOptions = null,
  isEditDisabled,
  onExportAll = () => null,
  exportAllButton = false,
  ...props
}) => {
  const t = useTranslator();
  const intl = useIntl();
  const {
    divisorAmount,
    currencySymbol,
    brandSelected,
    partnerSelected,
  } = useSession();
  const match = useRouteMatch();
  const { id, values } = useUserPreferences(
    brandSelected.id,
    partnerSelected.id,
    match.path,
    title
  );
  const theme = useTheme();
  const classes = useStyle();
  const { enqueueSnackbar } = useSnackbar();
  const tableRef = React.createRef();
  const [width, setWidth] = React.useState(null);
  const isClickable =
    onRowClick != null && typeof onRowClick === "function" ? true : false;
  const [selectedRow, setSelectedRow] = React.useState(isClickable ? 0 : null);
  const [inputList, setInputList] = React.useState({});
  const [exportAllModalOpen, setExportAllModalOpen] = React.useState(false);
  const [localSearchData, setLocalSearchData] = React.useState(null);
  const isRemote = onFetch !== undefined;
  const dispatch = useDispatch();
  const handleResize = React.useCallback(
    debounce(() => {
      if (tableRef.current) {
        setWidth(Math.floor(tableRef.current.getBoundingClientRect().width));
      }
    }, 500),
    [tableRef, setWidth]
  );
  const [reloadPagination, setReloadPagination] = React.useState(false);
  React.useEffect(() => {
    if (openedDetails != null) {
      const det = { ...openedDetails, ...inputList };
      setInputList(det);
    }
    // eslint-disable-next-line
  }, [openedDetails]);

  React.useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [tableRef, handleResize]);

  orderBy = isRemote
    ? {
        field:
          columns.findIndex((it) => it.field === orderBy?.field) !== -1
            ? columns.findIndex((it) => it.field === orderBy?.field)
            : 0,
        order: orderBy?.order,
      }
    : orderBy;

  const [maxColumns, setMaxColumns] = useState(0);
  const [filterColumns, setFilterColumns] = useState(columns);
  const [query, setQuery] = React.useReducer(
    (s, news) => {
      return { ...s, ...news };
    },
    { page: 0, pageSize: pageSize, orderBy }
  );
  const fetch = React.useCallback(
    debounce((q) => onFetch && onFetch(q), 300),
    [onFetch]
  );

  let isLoading = loading || (resize && (width == null || width === 0));

  React.useEffect(() => {
    let cols = columns;
    let counter = 1;
    cols = cols.map((it, index) => {
      let hiddenByUser = false;
      if (resize) {
        let obj = values.find((val) => it.field === val);
        if (obj) {
          hiddenByUser = true;
        }
      }
      const isHidden = resize && !(counter <= maxColumns && !hiddenByUser);
      if (!isHidden) {
        counter++;
      }
      return {
        ...it,
        hiddenByUser: hiddenByUser,
        renderData: it.render,
        render: undefined,
        hidden: isHidden,
      };
    });
    setFilterColumns(cols);
  }, [resize, columns, maxColumns, values]);

  React.useEffect(() => {
    if (width) {
      let maxCol = Math.max(
        1,
        Math.floor(width / 160) - (actions.length > 2 ? 2 : 1)
      );
      if (resize && maxCol !== maxColumns) {
        setMaxColumns(maxCol);
      }
    }
  }, [width, setMaxColumns, maxColumns, resize, actions.length]);

  React.useEffect(() => {
    const q = {
      ...query,
      orderBy: {
        ...query.orderBy,
        field: columns[query.orderBy?.field]?.field,
      },
    };
    fetch(q);
    // eslint-disable-next-line
  }, [query]);

  const isMobile = () => {
    return isMobileOnly;
  };

  const isTablet = () => {
    return (
      resize && width && width < theme.breakpoints.values.md && !isMobile()
    );
  };

  const [searchEnabled, setSearch] = React.useState(!isMobile());

  const onOrderChange = (column, order) => {
    let sortBy = null;
    let ord = order;
    if (isRemote) {
      const order = query.orderBy.order === "asc" ? "desc" : "asc";
      sortBy = { field: column, order };
    } else {
      if (column !== -1) {
        if (columns[column].order != null) {
          ord = columns[column].order === "asc" ? "desc" : "asc";
        }
        sortBy = {
          field: columns[column].sortField
            ? columns[column].sortField
            : columns[column].field,
          order: ord,
        };
      }
    }
    setQuery({ ...query, orderBy: sortBy });
  };

  const onChangePage = (page) => {
    setQuery({ page });
  };

  const onChangeRowsPerPage = (size) => {
    setQuery({ page: 0, pageSize: size });
  };

  const onSelectionChange = (rows) => {
    props.onSelectionChange && props.onSelectionChange(rows);
  };

  const getColumns = () => {
    const cols = filterColumns.map((it) => {
      let emptyValueCustom =
        it.emptyValueCustom != null ? it.emptyValueCustom : "-";

      if (it.renderData != null && typeof it.renderData === "function") {
        const render = it.renderData;
        it.renderData = (rowData) => {
          return render(rowData, { ...it });
        };
      }
      return {
        searchable: true,
        emptyValue:
          it.emptyValueJustify != null ? (
            <Box display={"flex"} justifyContent={it.emptyValueJustify}>
              {emptyValueCustom}
            </Box>
          ) : (
            emptyValueCustom
          ),
        ...it,
        render: (rowData) => <TableCell rowData={rowData} columnDef={it} />,
        width: it.hidden || it.hiddenByUser ? 0 : undefined,
        title: (
          <div className={"MuiTableCell-title"}>
            <Translate needle={it.title} />
          </div>
        ),
      };
    });

    if (isMobile()) {
      const getMobileClickableRow = (field, rowData) => {
        return isLoading ? (
          <Skeleton variant={"rect"} />
        ) : (
          <Box
            className={clsx(classes.mobileRowTitle, "mobileRowTitle")}
            style={{
              wordBreak: "break-word",
              whiteSpace: "normal",
            }}
          >
            {getRenderDataValue(rowData, field)}
          </Box>
        );
      };
      const defaultFields = cols.filter((it) => it.default);
      const [firstField, secondField] = defaultFields.length
        ? defaultFields
        : [cols[0]];
      return [
        {
          title: "",
          headerStyle: {
            display: "none",
          },
          cellStyle:
            isLoading || secondField === undefined
              ? { width: "100%" }
              : {
                  paddingRight: 5,
                  width: "75%",
                },
          width: undefined,
          searchable: false,
          sorting: false,
          render: (rowData) => getMobileClickableRow(firstField, rowData),
        },
        {
          title: "",
          headerStyle: {
            display: "none",
          },
          cellStyle: isLoading
            ? {}
            : {
                width: "25%",
                paddingRight: 5,
                paddingLeft: 5,
              },
          width: secondField ? undefined : 0,
          searchable: false,
          sorting: false,
          hidden: secondField === undefined,
          render: (rowData) => getMobileClickableRow(secondField, rowData),
        },
        ...cols.map((it) => {
          it.hidden = true;
          it.width = 0;
          return it;
        }),
      ];
    }
    if (isLoading) {
      if (cols.length === 0) {
        const loadingColumns = Array(5).fill(1);
        return [
          {
            title: "",
            field: "field",
            hidden: true,
            searchable: true,
            sorting: true,
          },
          ...loadingColumns.map((it, index) => {
            return {
              title: (
                <Box display="flex" justifyContent={"center"}>
                  <Skeleton variant={"rect"} width={"60%"} />
                </Box>
              ),
              field: index + "",
              ...it,
              sorting: false,
              hidden: false,
              emptyValue: <Skeleton variant={"rect"} width={"80%"} />,
            };
          }),
        ];
      } else {
        return cols.map((it, index) => ({
          ...it,
          hidden: it.hidden || it.hiddenByUser,
          width: undefined,
          emptyValue: <Skeleton variant={"rect"} width={"80%"} />,
        }));
      }
    }
    return cols;
  };

  const getFilterFieldValue = (startingValue, criteria) => {
    let finalValue =
      typeof startingValue === "object"
        ? { ...startingValue }
        : Array.isArray(startingValue)
        ? [...startingValue]
        : startingValue;
    if (criteria.type === CRITERIA_TYPE_SELECT && startingValue) {
      finalValue = getTranslatedLabels(
        startingValue,
        criteria.options,
        " - ",
        t
      );
    } else if (criteria.type === CRITERIA_TYPE_DATE && startingValue) {
      finalValue = moment(startingValue).format("L");
    } else if (
      (criteria.type === CRITERIA_TYPE_DATERANGE ||
        criteria.type === CRITERIA_TYPE_PERIOD) &&
      startingValue
    ) {
      let from = startingValue.from
        ? t({ needle: "label.filters.from" }) +
          moment(startingValue.from).format("L") +
          " "
        : "";
      let to = startingValue.to
        ? t({ needle: "label.filters.to" }) +
          moment(startingValue.to).format("L")
        : "";
      finalValue = from + to;
    } else if (criteria.type === CRITERIA_TYPE_DATETIMERANGE) {
      let from = startingValue.from
        ? t({ needle: "label.filters.from" }) +
          moment(startingValue.from).format("L LT") +
          " "
        : "";
      let to = startingValue.to
        ? t({ needle: "label.filters.to" }) +
          moment(startingValue.to).format("L LT")
        : "";
      finalValue = from + to;
    }
    return finalValue;
  };

  const getFilterFields = (localSearch) => {
    const [searchCriteria, searchTerms] = filters;
    const filterFields = [];
    if (searchCriteria) {
      searchCriteria.forEach((criteria) => {
        const value =
          searchTerms != null &&
          searchTerms.constructor.name === "Object" &&
          searchTerms[criteria.name]
            ? searchTerms[criteria.name]
            : "";
        filterFields.push([
          t({ needle: criteria.label }),
          getFilterFieldValue(value, criteria),
        ]);
      });
    }
    if (localSearch && localSearch !== "") {
      filterFields.push([t({ needle: "label.localSearch" }), localSearch]);
    }
    return {
      header: [t({ needle: "label.activeFiltersHeader" })],
      rows: filterFields,
    };
  };

  const exportFn = (exportType, tableData, includeFilters, localSearch) => {
    let exportColumns = filterColumns
      .filter((columnDef) => {
        return (
          !columnDef.hiddenByUser &&
          columnDef.field &&
          columnDef.export !== false
        );
      })
      .map((it) => ({ ...it, title: t({ needle: it.title }) }));
    const exportData = tableData.map((rowData) =>
      exportColumns.map((columnDef) => {
        if (columnDef.splitExportStringVal && exportType === ExportType.PDF) {
          var middle = Math.floor(rowData[columnDef.field].length / 2);
          var s1 = rowData[columnDef.field].substr(0, middle);
          var s2 = rowData[columnDef.field].substr(middle);
          return s1 + "\n" + s2;
        }
        if (typeof columnDef.getExportValueFn === "function") {
          return columnDef.getExportValueFn(rowData) || "";
        }
        if (typeof columnDef.getExportTypeFn === "function") {
          const styleType = columnDef.getExportTypeFn(rowData);
          if (styleType != null) {
            switch (styleType.style) {
              case STYLE_CURRENCY: {
                const currencyDisplay = currencySymbol ? "symbol" : "code";
                return intl.formatNumber(styleType.value / divisorAmount, {
                  style: STYLE_CURRENCY,
                  currency: styleType.currency,
                  currencyDisplay: currencyDisplay,
                });
              }
              case STYLE_LABEL: {
                return t({ needle: styleType.value });
              }
              default: {
                throw Error("Unrecognized style type");
              }
            }
          }
        }
        return getDataValue(rowData, columnDef) || "";
      })
    );
    exportColumns = exportColumns.map((it) => it.title);
    const filename =
      "Export_" + (props.exportFileName || t({ needle: title }) || "data");
    let filterFields = includeFilters ? getFilterFields(localSearch) : null;
    switch (exportType) {
      case ExportType.CSV: {
        dataExporter.exportCsv(
          exportColumns,
          exportData,
          filename,
          includeFilters,
          filterFields,
          t({ needle: title })
        );
        break;
      }
      case ExportType.EXCEL: {
        dataExporter.exportExcel(
          exportColumns,
          exportData,
          filename,
          includeFilters,
          filterFields,
          t({ needle: title })
        );
        break;
      }
      case ExportType.PDF: {
        dataExporter.exportPDF(
          exportColumns,
          exportData,
          filename,
          includeFilters,
          filterFields,
          t({ needle: title })
        );
        break;
      }
      default: {
        throw Error("Unrecognized export type");
      }
    }
  };

  let classTable = classes.simpleTable;
  if (type === "advanced") classTable = classes.advancedTable;
  if (isClickable) classTable += " " + classes.clickableTable;
  if (isMobile()) classTable += " " + classes.mobileTable;
  if (detail) classTable += " " + classes.collapsibleTable;
  const search = type === "advanced" && searchBar;
  const sorting =
    type !== "advanced"
      ? false
      : props.sorting || props.sorting === undefined
      ? true
      : false;
  const pagination = data && data.length === 0 ? false : props.pagination;

  const empty = (terms) => {
    return (
      terms == null ||
      Object.values(terms).filter((it) => {
        if (it == null) return false;
        if (Array.isArray(it)) return it.length > 0;
        return it !== "";
      }).length === 0
    );
  };

  const getEmptyAlert = () => {
    let array = data ? (isRemote ? data.data : data) : null;
    const [, searchTerms] = filters;

    return data === null ? (
      <NoDataToShow />
    ) : data && array && array.length ? (
      <NoDataToShow
        icon={NoSearchResultIcon}
        message={"warn.tableNoSearchResult"}
      />
    ) : empty(searchTerms) ? (
      <NoDataToShow icon={NoData} message={"warn.tableEmpty"} />
    ) : (
      <NoDataToShow
        icon={NoSearchResultIcon}
        message={"warn.tableNoSearchResult"}
      />
    );
  };

  const FormikMTInput = (props) => {
    const editCompDef =
      typeof props.columnDef.editComponentDef === "function"
        ? props.columnDef.editComponentDef(props.rowData)
        : props.columnDef.editComponentDef;

    return (
      <FormField
        {...editCompDef}
        fullWidth
        render={({ field, form }) => {
          const { name } = field;
          const { errors, setFieldValue } = form;
          return (
            <MTableEditField
              {...props}
              {...field}
              error={form.error && form.touched}
              onChange={(newValue) => setFieldValue(name, newValue)}
            />
          );
        }}
      />
    );
  };

  const MuiTableEditRow = ({ onEditingApproved, ...props }) => {
    const formFields = {};
    var labelPrefix = "";
    props.columns.forEach((it) => {
      if (it.editComponentDef != null) {
        const editCompDef =
          typeof it.editComponentDef === "function"
            ? it.editComponentDef(props.data)
            : it.editComponentDef;
        formFields[editCompDef.name] = editCompDef;
        labelPrefix = editCompDef.labelPrefix;
      }
    });
    return (
      <Formik
        validationSchema={getValidationSchema(
          labelPrefix,
          formFields,
          validationDependencies
        )}
        initialValues={props.data}
        onSubmit={(values) => {
          onEditingApproved(props.mode, values, props.data);
        }}
      >
        {(formikProps) => (
          <MTableEditRow
            {...props}
            onEditingApproved={formikProps.submitForm}
          />
        )}
      </Formik>
    );
  };

  const defaultComponents = {
    Container: ({ children, ...p }) => (
      <Paper
        {...p}
        elevation={0}
        className={isClickable ? classes.clickableTable : ""}
      >
        {children}
      </Paper>
    ),
    Toolbar: (p) => {
      const tableActions = [
        ...p.actions,
        {
          icon: Search,
          order: -1,
          tooltip: "button.search",
          onClick: () => {
            setSearch(true);
          },
          position: "toolbar",
          hidden: !isMobile() || searchEnabled,
          color: "inherit",
        },
        {
          icon: SaveAltIcon,
          p: {
            handleExport: exportFn,
            data: p.data,
            searchText: p.searchText,
          },
          menu: ExportMenu,
          color: "secondary",
          hidden:
            isLoading || (isMobile() && searchEnabled) ? true : !exportButton,
          position: "toolbar",
          tooltip: "button.export",
        },
        {
          icon: CloudDownloadOutlinedIcon,
          onClick: () => {
            setExportAllModalOpen(true);
          },
          color: "secondary",
          hidden: isLoading || !exportAllButton,
          position: "toolbar",
          tooltip: "button.exportAll",
        },
      ];
      if (isLoading) {
        tableProps.data.forEach((it) => (it.field = p.searchText));
      }

      if (isRemote) {
        const localCount = p.searchText !== "" ? p.renderData.length : null;
        setLocalSearchData(localCount);
      }

      return (
        <div
          className={clsx(classes.toolbar, {
            [classes.searchEnabled]: searchEnabled,
            [classes.toolbarMobile]: isMobile(),
            [classes.hasActions]:
              tableActions.filter(
                (it) => it.position === "toolbar" && !it.hidden
              ).length > 0,
          })}
        >
          <MTableToolbar
            {...p}
            title={isMobile() && searchEnabled ? null : p.title}
            actions={tableActions}
            search={p.search && searchEnabled}
          />
        </div>
      );
    },
    Pagination: (p) => {
      if (reloadPagination && !isRemote) {
        p.onChangeRowsPerPage({
          target: { value: 10 },
        });
        setReloadPagination(false);
      }
      const pageProps = {
        isLoading: isLoading,
        tablet: isTablet() || isMobile(),
        ...p,
        page: localSearchData > 0 ? 0 : p.page,
        count: localSearchData !== null ? localSearchData : p.count,
        showFirstLastPageButtons: type === "advanced",
      };

      return <Pagination {...pageProps} />;
    },
    Action: (p) => {
      let propsData = { ...p };

      if (isEditDisabled && propsData.data) {
        propsData.disabled = isEditDisabled(propsData.data);
      }
      return (
        <Action
          isLoading={isLoading}
          tablet={isTablet() || isMobile()}
          {...propsData}
          isEditDisabled={isEditDisabled != null}
          editTooltip={props.editDisabledTooltip}
        />
      );
    },
    Header: (p) => {
      let propsData = { ...p };
      if (isRemote) {
        propsData.orderBy = query.orderBy.field;
        propsData.orderDirection = query.orderBy.order;
      }
      return <MTableHeader {...propsData} />;
    },
    Body: (p) => {
      let propsData = { ...p };
      let renderData = propsData.renderData;

      if (propsData.hasDetailPanel && propsData.detailPanel != null) {
        renderData.forEach((d, i) => {
          if (
            Object.keys(inputList).includes(d[detailKey] + "") &&
            !d.tableData["added"]
          ) {
            d.tableData["showDetailPanel"] = inputList[d[detailKey]]
              ? propsData.detailPanel[0].render
              : null;
            d.tableData["added"] = true;
          } else {
            d.tableData["added"] = false;
          }
          delete inputList[d[detailKey]];
        });
        p = { ...p, renderData: renderData };
      }
      return <MTableBody {...p} />;
    },
    EditRow: MuiTableEditRow,
    EditField: FormikMTInput,
  };

  const tableComponents = { ...defaultComponents, ...components };

  const fakeData = [...Array(pageSize).keys()].map((it) => {
    return {
      field: it,
    };
  });
  let tableProps = {};
  if (isRemote) {
    tableProps = {
      onChangePage,
      onChangeRowsPerPage,
      onOrderChange,
    };
    if (isLoading) tableProps.data = fakeData;
    else {
      if (data) data.data = data.data ? data.data : [];
      tableProps = { ...tableProps, ...data };
    }
  } else {
    tableProps.data = isLoading ? fakeData : data ? data : [];
  }

  const handleChangeColumns = async (selected) => {
    const toUpdate = [...filterColumns];
    const index = toUpdate.indexOf(selected);
    toUpdate[index].hidden = !toUpdate[index].hidden;
    toUpdate[index].hiddenByUser = toUpdate[index].hidden;

    let userPreferences = {};
    userPreferences["url"] = match.path;
    userPreferences["componentIdentifier"] = title;
    userPreferences["brandId"] = brandSelected.id;
    userPreferences["partnerId"] = partnerSelected.id;
    const hiddenColumnsByUser = toUpdate.reduce((accumulator, it) => {
      if (it.hiddenByUser) {
        accumulator.push(it.field);
      }
      return accumulator;
    }, []);
    userPreferences["values"] = hiddenColumnsByUser.join(",");
    if (id) {
      userPreferences["id"] = id;
    }
    const url = `/gov/api/rest/v1/users-preferences`;
    await Axios.post(url, userPreferences);
    dispatch(
      getUserPreferencesHandler(
        brandSelected.id,
        partnerSelected.id,
        match.path,
        title
      )
    );
    setFilterColumns(toUpdate);
  };

  const onSearchChange = (searchText) => {
    if (
      !isMobile() &&
      resize &&
      maxColumns < filterColumns.length &&
      searchText !== ""
    ) {
      enqueueSnackbar(t({ needle: "label.table.hidden_column_search" }), {
        key: "table_message",
        preventDuplicate: true,
        variant: "info",
        autoHideDuration: 10000,
      });
    }
  };

  const tableColumns = getColumns();

  const getPageSize = (tableType, maxPageSize) => {
    let result = [5, 10, 25, 50, 100];
    result = result.filter((it) => it <= maxPageSize);
    if (tableType === "advanced" && !isRemote) {
      const maxRows =
        data?.length > 500
          ? 500
          : { value: data?.length, label: t({ needle: "label.all" }) };
      result.push(maxRows);
      return result;
    } else if (tableType === "advanced" && isRemote) {
      return result;
    } else {
      return [];
    }
  };

  React.useEffect(() => {
    if (!isRemote) {
      setReloadPagination(true);
    }
  }, [data]);

  const setOpenedRows = (row) => {
    const rowId = row[detailKey] + "";
    if (!Object.keys(inputList).includes(rowId + "") && !row.tableData.added) {
      inputList[rowId] = true;
      row.tableData.added = true;
    }
    setInputList(inputList);
  };

  return (
    <div className={classes.tableContainer + " " + classTable} ref={tableRef}>
      {resize && !isMobile() ? (
        <ColumnsSelector
          columns={filterColumns}
          onChange={handleChangeColumns}
          maxColumns={maxColumns}
        />
      ) : null}
      <MaterialTable
        title={title ? t({ needle: title }) : null}
        {...tableProps}
        columns={tableColumns}
        style={{ display: "flex", flexDirection: "column", overflowY: "auto" }}
        options={{
          thirdSortClick: isRemote ? false : true,

          detailPanelColumnStyle: {
            width: "auto",
            justifyContent: "center",
          },
          showSelectAllCheckbox: !isMobile(),
          detailPanelColumnAlignment: isMobile() ? "right" : "left",
          overflowY: isMobile() ? "initial" : null,
          showTitle: type === "advanced",
          toolbar: type === "advanced",
          padding: isMobile() || detail != null ? "default" : "dense",
          sorting: sorting,
          search: search,
          draggable: false,
          actionsColumnIndex: -1,
          paging: pagination,
          selection: isLoading ? false : props.selection,
          emptyRowsWhenPaging: false,
          pageSize: query.pageSize,
          pageSizeOptions: getPageSize(type, maxPageSize),
          debounceInterval: 500,
          rowStyle: (rowData) => {
            if (!isLoading && rowData.tableData.id === selectedRow) {
              return {
                backgroundColor: theme.palette.action.clickableSelected,
                color: theme.palette.action.clickableSelectedColor,
              };
            }
            if (lastRowColor) {
              if (data != null && rowData.tableData.id === data.length - 1) {
                return { backgroundColor: lastRowColor };
              }
            }
            if (tableOptions) {
              return tableOptions(rowData);
            }
          },
        }}
        icons={{
          FirstPage: React.forwardRef((p, ref) => (
            <span
              {...p}
              className={p.className + " PaginationIconButtons"}
              ref={ref}
            >
              <SkipPreviousIcon color={"inherit"} />
            </span>
          )),
          LastPage: React.forwardRef((p, ref) => (
            <span
              {...p}
              className={p.className + " PaginationIconButtons"}
              ref={ref}
            >
              <SkipNextIcon color={"inherit"} />
            </span>
          )),
          PreviousPage: React.forwardRef((p, ref) => (
            <span
              {...p}
              className={p.className + " PaginationIconButtons"}
              ref={ref}
            >
              <PlayArrowIcon color={"inherit"} />
            </span>
          )),
          SortArrow: React.forwardRef((p, ref) => <span {...p} ref={ref} />),
          Check: null,
          Clear: null,
          Search: React.forwardRef((p, ref) =>
            isMobile() ? (
              <ArrowBack {...p} ref={ref} onClick={() => setSearch(false)} />
            ) : (
              <Search {...p} ref={ref} />
            )
          ),
          ResetSearch: React.forwardRef((p, ref) => (
            <Cancel {...p} ref={ref} className={classes.clearIcon} />
          )),
        }}
        onSearchChange={onSearchChange}
        onSelectionChange={onSelectionChange}
        localization={{
          pagination: {
            labelRowsSelect: "",
            labelDisplayedRows: t({ needle: "label.pagination.displayedRows" }),
            labelRowsPerPage: t({ needle: "label.rowPerPage" }),
          },
          toolbar: {
            searchPlaceholder: t({
              needle: placeholder ? placeholder : "label.searchTable",
            }),
          },
          body: {
            emptyDataSourceMessage: <Box>{getEmptyAlert()}</Box>,
            editRow: {
              saveTooltip: "button.save",
              cancelTooltip: "button.cancel",
              deleteText: t({
                needle: "warn.tableDeleteRowAlert",
              }),
            },
            deleteTooltip: "button.delete",
            editTooltip: "button.edit",
          },
          header: { actions: t({ needle: "label.tableActions" }) },
        }}
        components={tableComponents}
        actions={!isMobile() ? actions : null}
        editable={
          !isMobile() && props.editable
            ? {
                onRowUpdate: props.rowUpdate,
                onRowDelete: props.rowDelete,
              }
            : null
        }
        detailPanel={
          isMobile() || detail
            ? [
                {
                  icon: "expand_more",
                  openIcon: "expand_less",
                  render: isMobile()
                    ? (rowData) => (
                        <ListTable
                          isLoading={isLoading}
                          data={rowData}
                          columns={tableColumns}
                          detail={detail}
                          editable={props.editable}
                          actions={actions}
                        />
                      )
                    : (rowData) => {
                        setOpenedRows(rowData);
                        const detailTable = detail(rowData);
                        return detailTable;
                      },
                },
              ]
            : null
        }
        onRowClick={
          isMobile() || detail != null || isClickable
            ? (event, rowData, togglePanel) => {
                if (isMobile() || (detail != null && !rowClickDisabled)) {
                  setOpenedRows(rowData);
                  togglePanel();
                } else {
                  if (onRowClick != null && typeof onRowClick === "function") {
                    onRowClick(event, rowData);
                    setSelectedRow(rowData.tableData.id);
                  }
                }
              }
            : null
        }
      />
      <Modal
        open={exportAllModalOpen}
        title={t({ needle: "label.exportCofiguration" })}
        content={
          <ExportAllModalContent
            columns={columns}
            exportlines={tableProps.totalCount}
            handleClose={() => setExportAllModalOpen(false)}
            handleConfirm={(col) => {
              onExportAll(
                col,
                true,
                true ? getFilterFields() : null,
                t({ needle: title })
              );
              setExportAllModalOpen(false);
            }}
          ></ExportAllModalContent>
        }
      />
    </div>
  );
};

const RefComponent = PropTypes.shape({ current: PropTypes.element });

Table.propTypes = {
  title: PropTypes.string,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      defaultSort: PropTypes.oneOf(["asc", "desc"]),
      hidden: PropTypes.bool,
      render: PropTypes.func,
      sortField: PropTypes.string,
      sorting: PropTypes.bool,
    })
  ),
  type: PropTypes.oneOf(["advanced", "simple"]),
  isLoading: PropTypes.bool,
  onFetch: PropTypes.func,
  data: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.shape({
      data: PropTypes.array.isRequired,
      page: PropTypes.number.isRequired,
      totalCount: PropTypes.number.isRequired,
    }),
  ]),
  actions: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({
        icon: PropTypes.oneOfType([
          PropTypes.element,
          PropTypes.func,
          PropTypes.string,
          RefComponent,
        ]).isRequired,
        position: PropTypes.oneOf([
          "auto",
          "toolbar",
          "toolbarOnSelect",
          "row",
        ]),
        tooltip: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        hidden: PropTypes.bool,
        permission: PropTypes.string,
      }),
    ])
  ),
  pageSize: PropTypes.number,
  orderBy: PropTypes.shape({
    field: PropTypes.string,
    order: PropTypes.oneOf(["asc", "desc"]),
  }),
  exportButton: PropTypes.bool,
  filters: PropTypes.array,
  search: PropTypes.bool,
  onSelectionChange: PropTypes.func,
  editable: PropTypes.bool,
  rowUpdate: PropTypes.func,
  rowDelete: PropTypes.func,
  exportFileName: PropTypes.string,
  sorting: PropTypes.bool,
  pagination: PropTypes.bool,
  selection: PropTypes.bool,
  lastRowColor: PropTypes.string,
  onRowClick: PropTypes.func,
  placeholder: PropTypes.string,
  detail: PropTypes.func,
};

const useStyle = makeStyles((theme) => ({
  tableContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  simpleTable: {
    "& .MuiTableRow-root": {
      borderLeft: "none!important",
      "& .MuiTableCell-root:last-child": {
        borderRight: "none!important",
      },
    },
    "& .MuiTablePagination-caption": {
      display: "none",
    },
    "& .MuiTablePagination-actions": {
      flex: "0 0 auto",
      justifySelf: "flex-end",
    },
    "& .previous": {
      transform: "rotate(-180deg)",
    },
    "& .MuiTableCell-head": {
      backgroundColor: theme.palette.background.paper + "!important",
    },
    "& .MuiTableRow-hover": {
      cursor: "auto !important",
    },
  },
  advancedTable: {
    "& .MuiTableCell-head": {
      backgroundColor: theme.palette.background.primary2Accent2,
    },
    "& .previous": {
      transform: "rotate(-180deg)",
    },
    "& .MuiTablePagination-toolbar": {
      backgroundColor: theme.palette.background.primary2Accent2,
      minHeight: "34px",
      paddingLeft: 20,
      paddingRight: 5,
    },
    "& .MuiTableRow-hover": {
      cursor: "auto !important",
    },
  },
  clickableTable: {
    backgroundColor: theme.palette.backgroundClickable.paper,
    "& .MuiTableCell-head": {
      backgroundColor: theme.palette.backgroundClickable.paper + "!important",
    },
  },
  mobileTable: {
    overflowX: "hidden",
    "& th": {
      display: "none",
    },
    "& .MuiTableBody-root > .MuiTableRow-root": {
      backgroundColor: "transparent",
    },
    "& .MuiTableCell-root": {
      borderRight: "none",
    },
  },
  toolbarMobile: {
    "& .MuiToolbar-root": {
      "& > div:nth-child(2)": {
        flex: 1,
      },
      "& > div:nth-child(3)": {
        "& .MuiIconButton-root": {
          color: "white",
          padding: 6,
          "& svg": {
            fontSize: 30,
          },
        },
        "& > div > div": {
          display: "flex",
          "& > span:nth-child(1n+2):before": {
            content: "''",
            padding: "8px 0px 12px",
            borderLeft: "1px solid white",
            margin: "0px 5px",
          },
        },
      },
    },
  },
  mobileRowTitle: {
    fontWeight: theme.typography.fontWeightBold,
  },
  collapsibleTable: {
    "& .MuiTableRow-root": {
      "& .MuiTableCell-root:first-child": {
        width: 20,
      },
      "& .MuiTableCell-footer:first-child": {
        width: "100%",
      },
    },
  },
  searchEnabled: {},
  hasActions: {},
  toolbar: {
    "& .MuiToolbar-root": {
      background: theme.palette.primary.main,
      color: theme.palette.text.secondary2,
      textTransform: "uppercase",
      padding: "0 5px 0 20px",
      minHeight: "44px",
      borderTopLeftRadius: 3,
      borderTopRightRadius: 3,
      "& h6": {
        fontWeight: theme.typography.fontWeightBold,
        fontSize: theme.typography.fontSize,
      },
    },
    "&$searchEnabled$toolbarMobile .MuiToolbar-root": {
      padding: "0 5px",
      "& > div:nth-child(1)": {
        display: "none",
      },
    },
    "& .MuiFormControl-root": {
      margin: "5px 0",
    },
    "&:not($toolbarMobile)$hasActions": {
      "& .MuiFormControl-root": {
        "& + div": {
          marginLeft: 10,
          paddingLeft: 10,
          borderLeft: "1px solid white",
        },
      },
    },
    "&$toolbarMobile .MuiFormControl-root": {
      flex: 1,
      paddingLeft: 0,
    },
    "& .MuiInputBase-root": {
      background: theme.palette.background.paper,
      "& .MuiInputAdornment-root, & .MuiIconButton-root": {
        color: theme.palette.text.icon,
      },
    },
    '&:not($toolbarMobile) button[aria-label="Export"]': {
      background: theme.palette.secondary.main,
      borderRadius: theme.shape.borderRadius,
      color: theme.palette.text.secondary1,
      height: 36,
      padding: theme.spacing(1, 2),
    },
    "& .MuiTextField-root": {
      "& .MuiIconButton-root": {
        background: "none !important",
      },
    },
    "& .MuiInputAdornment-positionEnd": {},
    "& .MuiInputAdornment-positionStart": {
      marginLeft: theme.spacing(1),
    },
    '& .MuiInput-input[value=""] + .MuiInputAdornment-positionEnd': {
      visibility: "hidden",
    },
  },
}));

export default Table;
